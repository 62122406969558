import { AccountInfoResponse } from '../model/profile/accountinforesponse';
import { ProfileDetailsVO } from '../model/profile/profilevisitdetailsvo';
import AuthTypes from './auth.types';

const authAction = {

    setUserDetails: (userDetails: any) => {
        return (dispatch: any) => {
            dispatch({ type: AuthTypes.SET_USER_DETAILS, payload: userDetails });
        };
    },

    setLisUserDetails: (userDetails: any) => {
        return (dispatch: any) => {
            dispatch({ type: AuthTypes.SET_LIS_USER_DETAILS, payload: userDetails });
        };
    },

    updateUserDetails: (profileDetails: ProfileDetailsVO, authDetails: AccountInfoResponse) => {
        // return (dispatch: any) => {
        //     dispatch({ type: AuthTypes.UPDATE_USER_DETAILS, payload: userDetails });
        // };
        authDetails = {
            ...authDetails,
            ...profileDetails
        }
        return (dispatch: any) => {
            dispatch({ type: AuthTypes.SET_USER_DETAILS, payload: authDetails });
        };
    },
    updateAddressInAuth: (addList, authDetails: AccountInfoResponse) => {
        authDetails = {
            ...authDetails,
            contactInfo: {
                ...authDetails.contactInfo,
                addresses: addList
            }
        }
        return (dispatch: any) => {
            dispatch({ type: AuthTypes.SET_USER_DETAILS, payload: authDetails });
        };
    },

    updateLastLoginPage: (timestamp: number) => {
        return (dispatch: any) => {
            dispatch({ type: AuthTypes.LAST_LOGIN_PAGE_TIME, payload: timestamp });
        };
    },

    logout: () => {
        return (dispatch: any) => {
            dispatch({ type: AuthTypes.SET_LOGOUT, payload: null });
        };
    }
}

export default authAction;