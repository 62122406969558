import AxiosService from '../portal_env_config/axiosService';
import { paths } from '../portal_env_config/urlconstants';
import Auth from '../../auth/auth';
import { BaseResponse } from '../../model/base/baseresponse';
import ToastComponent from '../../components/layout/widgets/toast/toast.component';
import StorageUtil from '../../auth/util/storageUtil';

export default class FileUtil {
    private getTempFileURLFromSecureURL(urls, userId = Auth.getAuthState()?.auth?.userDetails?.profileId): Promise<any> {
        return AxiosService.httpGet(paths.GET_TEMP_FILE_URL_FROM_SECURE, { urls: encodeURI(urls), userId }).then((data) => {
            return data.data;
        }).catch((err) => {
            if (err) {
                console.log(err);
                return err;
            }
        });
    }
    private getTempUrl(url: string): Promise<any> {
        return AxiosService.httpGet(paths.GET_TEMP_FILE_URL_FROM_UUID + '/' + url).then((data: BaseResponse) => {
            return Promise.resolve(data.data);
        }).catch((err) => {
            if (err) {
                console.log(err);
                return err;
            }
        });
    }
    public getFileURL(pdfUrl: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let toLowerCaseurl = '' + pdfUrl?.toLowerCase();
            let fileExtensions: string[] = ['.pdf', '.xlsx', '.xlx', '.xls', '.png', '.jpg', '.jpeg'];
            let index = -1;
            for (let i = 0; i < fileExtensions.length; i++) {
                let j = toLowerCaseurl.indexOf(fileExtensions[i]);
                index = (j != -1) ? j : index;
            }
            if (index == -1) {
                console.log('enter this condition');
                this.getTempUrl(pdfUrl?.trim()).then((url) => {
                    if ((url.statusCode == 201 || url.statusCode == 200)) {
                        resolve(url.data);
                    } else {
                        resolve(url.data);
                    }
                }).catch((err) => {
                    reject(err)
                })
            } else if (((toLowerCaseurl.length - 5) <= index) && (index < toLowerCaseurl.length)) {
                this.getTempFileURLFromSecureURL(pdfUrl).then((url) => {
                    if ((url.statusCode == 201 || url.statusCode == 200)) {
                        resolve(url.data);
                    } else {
                        resolve(url.data);
                    }
                }).catch((err) => {
                    reject(err)
                })
            } else {

                resolve(pdfUrl);
            }
        })
    }
    public openFile(secureFile) {
        let windowReference = window.open('');
        this.getFileURL(secureFile).then((tempFile) => {
            windowReference.location = tempFile;
        })
    }
    public static async openFile(secureFile) {
        let windowReference = window.open('', '_blank');
        await new FileUtil().getFileURL(secureFile).then((tempFile) => {
            if (windowReference) {
                windowReference.location = tempFile;
            } else if (window) {
                window.open(tempFile, '_blank');
            }
        })
    }
    public static async printFile(secureFile) {
        let windowReference = window.open('', '_blank');
        if (secureFile && secureFile?.trim().startsWith("http")) {
            FileUtil.printExternalFile(secureFile, windowReference);
        } else {
            await new FileUtil().getFileURL(secureFile).then((tempFile) => {
                if (window.print) {
                    windowReference.location = tempFile;
                }
            }).catch(error => {
                console.error(error);
            })
        }
    }
    public static printExternalFile(tempFile, windowReference = null) {
        // let windowReference = window.open('', '_blank');

        if (windowReference && window.print) {
            windowReference.location = tempFile;
        } else {

            window.open(tempFile, '_blank');
        }
    }

    public static fileUploadToAwsS3(awsCredentials, uploadFile): Promise<any> {
        let fileName: string = `${Date.now()}_${uploadFile.name}`.replace(/[<>:"\/\\|?*]+/g, '');
        let dirFileName = awsCredentials && awsCredentials.dirName ? `${awsCredentials.dirName}/${fileName}` : fileName;
        return new Promise((resolve, reject) => {
            AxiosService.httpGet(paths.GET_TEMP_UPLOAD_FILE_URL_FROM_SECURE, { filePath: dirFileName }).then((response) => {
                let fileData = response.data.data;
                AxiosService.uploadFile(fileData.signedUrl, uploadFile).then(res => {
                    return resolve({ key: fileData.fileName, Location: fileData.fileUrl });
                }).catch(error => {
                    ToastComponent.warning(`Something went wrong while uploading.`);
                    console.log(error);
                    reject(error);
                });
            }).catch((err) => {
                if (err) {
                    ToastComponent.warning(`Something went wrong while uploading.`);
                    console.log(err);
                    reject(err);
                }
            });
        });
    }

    public static checkWebPSupport() {
        let data = StorageUtil.getUnencryptedLocalStorageData("webpSupport");
        if ((data && data == "true") || (typeof window == 'undefined')) {
            return true;
        } else {
            return false;
        }
    }

}