import { Address } from "../../model/profile/profileaddress";
import { ACCOUNT_DELETED, CART_SELECTED_ADDRESS, GET_INTERESTS, GET_LANGUAGES, GET_PROFILE_DETAILS, USER_ADDRESSES } from "./profile.types";

export default {
    setProfileDetails(details) {
        return (dispatch: any) => {
            dispatch({ type: GET_PROFILE_DETAILS, payload: details });
        }
    },
    setLanguages(languages) {
        return (dispatch: any) => {
            dispatch({ type: GET_LANGUAGES, payload: languages });
        }
    },
    setInterests(interestList) {
        return (dispatch: any) => {
            dispatch({ type: GET_INTERESTS, payload: interestList });
        }
    },
    setUserAddressList(list) {
        list?.forEach((address) => {
            address.label = address.addressType == Address.ADDRESS_HOME ? 'Home Address' :
                (address.addressType == Address.ADDRESS_OFFICE ? 'Office Address' :
                    (address.addressType == Address.ADDRESS_OTHER && (address.label != undefined && address.label != null &&
                        address.label.length > 0) ? address.label : 'Other Address'));
        });
        return (dispatch: any) => {
            dispatch({ type: USER_ADDRESSES, payload: list });
            // dispatch({ type: CART_SELECTED_ADDRESS, payload: list[0] })
        }
    },
    setSelectedAddress(address) {
        return (dispatch: any) => {
            dispatch({ type: CART_SELECTED_ADDRESS, payload: address });
        }
    },
    setDeleteAccountStatus() {
        return (dispatch: any) => {
            dispatch({ type: ACCOUNT_DELETED});
        }
    },
}